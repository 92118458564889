import { FC, useState, useEffect } from 'react';
import { ToastContainer as TC, Slide } from 'react-toastify';
import { createPortal } from 'react-dom';

import 'react-toastify/dist/ReactToastify.css';

const getToastRoot = () => {
  let ToastRoot = document.getElementById('toast-root');

  if (ToastRoot === null) {
    ToastRoot = document.createElement('div');

    ToastRoot.setAttribute('id', 'toast-root');

    document.body.appendChild(ToastRoot);
  }

  return ToastRoot;
};

const ToastContainer: FC = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => setIsMounted(true), []);

  return isMounted
    ? createPortal(
        <TC
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          limit={5}
          transition={Slide}
          theme="dark"
        />,
        getToastRoot()
      )
    : null;
};

export default ToastContainer;

import Head from 'next/head';
import { ReactElement, ReactNode } from 'react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/nextjs';

import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import type { AxiosError } from 'axios';

import '@gate-academy/shared/dist/styles/vars.css';
import '@gate-academy/shared/dist/styles/base.css';
import '~/styles/session-events.css';

import ProgressBar from '../components/progress-bar/progress-bar.component';
import ToastContainer from '../components/toast-container/toast-container.component';

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 10,
      cacheTime: 1000 * 60 * 10,
      onError: (error) => {
        const message = (error as AxiosError<{ message: string }>)?.response
          ?.data?.message;

        toast.error(message || 'Ooops!! Something went wrong');

        Sentry.captureException(error);
      },
    },
    mutations: {
      onError: (error) => {
        const message = (error as AxiosError<{ message: string }>)?.response
          ?.data?.message;

        toast.error(message || 'Ooops!! Something went wrong');

        Sentry.captureException(error);
      },
    },
  },
});

function MyApp({ Component, pageProps }: AppPropsWithLayout): ReactElement {
  const getLayout = Component.getLayout ?? ((page: ReactElement) => page);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>

      <ProgressBar />

      <ToastContainer />

      <QueryClientProvider client={queryClient}>
        {getLayout(<Component {...pageProps} />)}

        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}

export default MyApp;

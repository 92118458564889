import { FC, useEffect } from 'react';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';

import 'nprogress/nprogress.css';

const ProgressBar: FC = () => {
  const router = useRouter();

  useEffect(() => {
    const delay = 500; // in milliseconds

    let timer: NodeJS.Timeout;

    const load = () => {
      timer = setTimeout(() => {
        NProgress.start();
      }, delay);
    };

    const stop = () => {
      clearTimeout(timer);

      NProgress.done();
    };

    router.events.on('routeChangeStart', () => load());
    router.events.on('routeChangeComplete', () => stop());
    router.events.on('routeChangeError', () => stop());

    return () => {
      router.events.off('routeChangeStart', () => load());
      router.events.off('routeChangeComplete', () => stop());
      router.events.off('routeChangeError', () => stop());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <style jsx global>
      {`
        #nprogress .bar {
          background: rgb(var(--color-primary));
          height: 2.5px;
        }

        #nprogress .spinner {
          right: 0;
          left: 15px;
        }

        #nprogress .spinner-icon {
          border-top-color: rgb(var(--color-secondary));
          border-left-color: rgb(var(--color-secondary));
        }
      `}
    </style>
  );
};

export default ProgressBar;
